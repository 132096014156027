import qs, { type IParseOptions, type IStringifyOptions } from "qs";

export const STRINGIFY_OPTIONS: IStringifyOptions = {
	// required to stringify arrays into comma separated strings
	arrayFormat: "comma",
	// don't use array indices
	indices: false,
	// don't encode the entire string as it will be done individually for certain params
	encode: false,
	// required to remove empty params
	skipNulls: true,
};

export const PARSE_OPTIONS: IParseOptions = {
	// required to parse comma separated string into array
	comma: true,
};

export interface RouteState {
	truckTags: string | undefined;
	truckIds: string[] | undefined;
	date: string
}

export const parseRoute = (locationSearch: string): RouteState => {
	const decodedSearch = decodeURIComponent(locationSearch.replace("?", ""));
	const { truckTags, truckIds, sb, testa, date } = qs.parse(decodedSearch, PARSE_OPTIONS);
	return {
		truckTags,
		date,
		truckIds,
		sb,
		testa,
	} as RouteState;
};

export const makeRoute = (searchParams: RouteState): string => {
	const queryParams = qs.stringify(searchParams, STRINGIFY_OPTIONS);
	const searchFragment = queryParams && `?${queryParams}`;
	return encodeURI(searchFragment);
};
