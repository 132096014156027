
export const rangeToString = (range: [number, number]) => `${range[0]}-${range[1]}`;

export const rangesToString = (ranges: [number, number][]) => ranges.map(rangeToString).join(",");

export const stringToRange = (rangeStr: string): [number, number] => {
    const [start, end] = rangeStr.split("-").map(Number);
    return [start, end];
};

export const stringToRanges = (rangesStr: string) => {
    return rangesStr.split(",").flatMap((rangeStr) => {
        const [start, end] = stringToRange(rangeStr);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    });
};

export const getRangesFromIds = (ids: number[]) => {
    if (ids.length === 0) return [];

    const idsCopy = [...ids];

    idsCopy.sort((a, b) => a - b);

    const ranges: [number, number][] = [];
    let start = idsCopy[0];
    let end = idsCopy[0];

    for (let i = 1; i < idsCopy.length; i++) {
        if (idsCopy[i] === end + 1) {
            end = idsCopy[i];
        } else {
            ranges.push([start, end]);
            start = idsCopy[i];
            end = idsCopy[i];
        }
    }
    ranges.push([start, end]);

    return ranges;
};


