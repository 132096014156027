import { AccountConfigFlags } from "../constants/enum";

interface AccountConfig {
    [key: string]: Set<string>;
}

const accountConfig: AccountConfig = {
    [AccountConfigFlags.DigitalScheduleBeta]: new Set([
        '40ccb35f-f2bb-4e50-b121-6f7b366b9408',
        'local'
    ]),
    [AccountConfigFlags.VolksUptime]: new Set([
        'b1cbe9de-be81-4c67-9a8f-bdcbc782ab50',
        'local'
    ]),
    [AccountConfigFlags.PresentationAccountIds]: new Set([
        "7c821519-a280-43a6-8746-2c82b00530ed", // frota demonstração
        "4924568e-23f3-40f9-9730-ff88efc41172", // JSP
        'local'
    ])
};

export const checkAccountFlag = (accountId: string, flag: AccountConfigFlags): boolean => {
    return accountConfig[flag]?.has(accountId) ?? false;
};